import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import { NetworkManager } from '../services/NetworkManager';
import { Constants } from '../constants';

interface PartType {
  id: number;
  name: string;
}

const PartTypesList = () => {
  const [partTypes, setPartTypes] = useState<PartType[]>([]);
  const [newPartType, setNewPartType] = useState('');
  const networkManager = new NetworkManager(Constants.API_URL);
  
  useEffect(() => {
    async function fetchPartTypes() {
      try {
        const response = await networkManager.get('/api/parttypes/');
        setPartTypes(response.data);
      } catch (error) {
        console.error('Error fetching part types:', error);
      }
    }

    fetchPartTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle new part type addition
  const handleAddPartType = async () => {
    if (!newPartType) return;

    try {
      const response = await networkManager.post('/api/parttypes/', {
        name: newPartType,
      });
      setPartTypes((prev) => [...prev, response.data]);
      setNewPartType(''); // Clear input field after adding
    } catch (error) {
      console.error('Error adding new part type:', error);
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: '600px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Alkatrésztípusok
      </Typography>

      {/* Input field for adding new part types */}
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          fullWidth
          label="Új alkatrésztípus"
          variant="outlined"
          value={newPartType}
          onChange={(e) => setNewPartType(e.target.value)}
        />
        <IconButton color="primary" onClick={handleAddPartType} sx={{ ml: 1 }}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Divider for visual separation */}
      <Divider sx={{ mb: 2 }} />

      {/* List of part types */}
      <List>
        {partTypes.map((partType) => (
          <ListItem key={partType.id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={partType.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PartTypesList;