import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from '../user/login';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff',
    },
    background: {
      default: '#0d1b2a',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const LoginScreen: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    if (username === '' || password === '') {
      setErrorMessage('Please fill out both fields.');
    } else {
      setErrorMessage('');
      
      try {
        const response = await login(username, password);

        if (response) {
          window.location.href = '/';
        }
      } catch (error) {
        console.error('Login error:', error);
        setErrorMessage('Login failed. Please check your credentials.');
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #1b2735 0%, #090a0f 100%)',
          backgroundImage: 'url("https://szerviz.slimstore.hu/szerviz.jpg")', // Set your background image here
          backgroundSize: 'cover',
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              borderRadius: 5,
              textAlign: 'center',
            }}
          >
            
            {errorMessage && (
              <Typography color="error" gutterBottom>
                {errorMessage}
              </Typography>
            )}
            <form onSubmit={handleLogin}>
              <TextField
                label="Felhasználónév"
                type="username"
                variant="outlined"
                fullWidth
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="Jelszó"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Bejelentkezés
              </Button>
            </form>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default LoginScreen;