import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const accessToken = localStorage.getItem('accessToken');
  
  if (!accessToken) {
    // Ha nincs access token, akkor irányítsuk át a bejelentkezési oldalra
    return <Navigate to="/" />;
  }

  // Ha van access token, akkor mutatjuk a gyerek elemeket (pl. Dashboard)
  return children;
};

export default ProtectedRoute;