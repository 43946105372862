import { NetworkManager } from '../services/NetworkManager';

const apiManager = new NetworkManager('http://localhost:8000');

// Login függvény
export async function login(username: string, password: string) {
  try {
    const response = await apiManager.post('/api/token/', {
      username: username,
      password: password,
    });

    // Ellenőrizzük, hogy van-e accessToken és refreshToken a válaszban
    const { access, refresh } = response.data;

    if (access && refresh) {
      console.log(access);
      // Tokenek elmentése localStorage-be (vagy sessionStorage-be)
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
    } else {
      console.error('Tokenek nem találhatóak a válaszban.');
    }

    return response;
  } catch (error) {
    console.error('Login hiba:', error);
    throw error;
  }
}