export class UserManager {
    private static instance: UserManager;
    private name: string;
    private accessToken: string;
    private refreshToken: string;
  
    // Privát konstruktor, hogy a Singleton minta működjön
    private constructor() {
      this.name = localStorage.getItem('name') || '';
      this.accessToken = localStorage.getItem('accessToken') || '';
      this.refreshToken = localStorage.getItem('refreshToken') || '';
    }
  
    // Singleton példány visszaadása
    public static getInstance(): UserManager {
      if (!UserManager.instance) {
        UserManager.instance = new UserManager();
      }
      return UserManager.instance;
    }
  
    // Felhasználó nevének lekérdezése
    public getName(): string {
      return this.name;
    }
  
    // Access token lekérdezése
    public getAccessToken(): string {
      return this.accessToken;
    }
  
    // Refresh token lekérdezése
    public getRefreshToken(): string {
      return this.refreshToken;
    }
  
    // Kilépés funkció
    public logout(): void {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('name');
      this.name = '';
      this.accessToken = '';
      this.refreshToken = '';
      window.location.href = '/';
    }
  
    // Token frissítése
    public async refreshTokenFunc(): Promise<void> {
      try {
        const response = await fetch('http://localhost:8000/api/token/refresh/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refresh: this.refreshToken,
          }),
        });
  
        const data = await response.json();
        if (data.access) {
          this.accessToken = data.access;
          localStorage.setItem('accessToken', data.access);
          console.log('Access token frissítve.');
        } else {
          throw new Error('Token frissítési hiba');
        }
      } catch (error) {
        console.error('Token frissítési hiba:', error);
        this.logout();
      }
    }
  }