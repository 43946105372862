import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginScreen from './components/LoginForm';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute'; // Importáljuk a védett útvonal komponenst

function App() {
  return (
    <Router>
      <Routes>
        {/* Ha a felhasználó a "/" útvonalon van és be van jelentkezve, irányítsa a dashboardra */}
        <Route
          path="/"
          element={
            localStorage.getItem('accessToken') ? (
              <Navigate to="/dashboard" />
            ) : (
              <LoginScreen />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;