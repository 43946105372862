import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Box,
  CssBaseline,
  ListItemIcon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BuildIcon from '@mui/icons-material/Build';
import InventoryIcon from '@mui/icons-material/Inventory';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PieChartSharpIcon from '@mui/icons-material/PieChartSharp';
import PartTypesList from './PartTypes';
import { UserManager } from '../services/UserManager';
import PhoneTypesList from './PhoneTypes';
import PartsList from './InventoryManager';

const drawerWidth = 250;
const appBarHeight = 64; // Az AppBar tipikus magassága pixelben

// Például ezek lehetnek különálló komponensek, amelyeket be szeretnél illeszteni
const Servizek = () => <Typography variant="h5">Szervizek Tartalom</Typography>;
const Raktarkeszlet = () => <PartsList />;
const Telefontipusok = () => <PhoneTypesList />
const Alkatresztipusok = () => <PartTypesList />;

export const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState('dashboard');
  const userManager = UserManager.getInstance();
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    userManager.logout();
  };

  // Tartalom dinamikus megjelenítése
  const renderContent = () => {
    switch (selectedContent) {
      case 'szervizek':
        return <Servizek />;
      case 'raktarkeszlet':
        return <Raktarkeszlet />;
      case 'telefontipusok':
        return <Telefontipusok />;
      case 'alkatresztipusok':
        return <Alkatresztipusok />;
      default:
        return (
          <>
            <Typography variant="h4">Welcome to Admin Dashboard</Typography>
            <Typography variant="body1">
              This is where you can manage your application.
            </Typography>
          </>
        );
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />

      {/* AppBar rögzítve a képernyő tetejére */}
      <AppBar position="fixed" sx={{ backgroundColor: '#FFB80A', color: 'black', zIndex: (theme) => theme.zIndex.drawer }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: 'black', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => setSelectedContent('')}>
            Slim Service - Leltár
          </Typography>
          <IconButton
            edge="end"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ color: 'black' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (jobb oldali menü) */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ width: drawerWidth }}
      >
        <Box
          sx={{ width: drawerWidth }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItemButton onClick={() => setSelectedContent('szervizek')}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Szervizek" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('raktarkeszlet')}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="Raktárkészlet" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('telefontipusok')}>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary="Telefontípusok" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedContent('alkatresztipusok')}>
              <ListItemIcon>
                <PieChartSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Alkatrésztípusok" />
            </ListItemButton>

            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Kijelentkezés" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>

      {/* Tartalom, amely az AppBar alatt kezdődik */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: `${appBarHeight}px` }}>
        {renderContent()} {/* A tartalom dinamikusan jelenik meg */}
      </Box>
    </Box>
  );
};

export default Dashboard;